<template>
  <div>
    <badge :type="getBadgeType()">
      {{
        $t(`SALES_ORDERS.CLIENT_VALIDATED_STATUS_${salesOrder.client_validated_status}`)
      }}
    </badge>
  </div>
</template>

<script>
import {
  CLIENT_VALIDATED_STATUS_DRAFT,
  CLIENT_VALIDATED_STATUS_CANCELED,
  CLIENT_VALIDATED_STATUS_VALIDATED,
} from "@/constants/salesOrders";

export default {
  name: "sales-order-client-validated-status-badge",

  components: {},

  mixins: [],

  props: ["salesOrder"],

  data() {
    return {};
  },

  computed: {},

  methods: {
    getBadgeType() {
      switch (this.salesOrder.client_validated_status) {
        case CLIENT_VALIDATED_STATUS_DRAFT:
          return "primary";
        case CLIENT_VALIDATED_STATUS_CANCELED:
          return "danger";
        case CLIENT_VALIDATED_STATUS_VALIDATED:
          return "success";
        default:
          break;
      }
      return "default";
    },
  },

  mounted() {},

  watch: {},
};
</script>
